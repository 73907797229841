body {
    font-size: 14px;
    padding-bottom: 30px;
}

.datepicker {
    font-size: 18px;
}

.sum_high {
    background-color: lightgreen;
}
